// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x94i8ma4bM0Cl5uewHe_ {\n    width: 100%;\n    height: 45vh;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: #e5e5e5;\n}\n\n.ak5AppG7z2V3KA950D31 {\n    font-size: 100px;\n}\n\n.Q1_MQOhhq0l_qgqcG4Mg {\n    font-size: 18px;\n    color: #2c374a;\n    margin-top: 0;\n}\n\n@media only screen and (hover: none) and (pointer: coarse) and (orientation:portrait) {\n    .x94i8ma4bM0Cl5uewHe_ {\n        height: 60vh;\n    }\n\n    .ak5AppG7z2V3KA950D31 {\n        font-size: 200px;\n    }\n\n    .Q1_MQOhhq0l_qgqcG4Mg {\n        font-size: 45px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/main-app/Styles/DisabledUpload.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,aAAa;AACjB;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".disabled_upload_container {\n    width: 100%;\n    height: 45vh;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: #e5e5e5;\n}\n\n.ellipsis_icon {\n    font-size: 100px;\n}\n\n.upload_text {\n    font-size: 18px;\n    color: #2c374a;\n    margin-top: 0;\n}\n\n@media only screen and (hover: none) and (pointer: coarse) and (orientation:portrait) {\n    .disabled_upload_container {\n        height: 60vh;\n    }\n\n    .ellipsis_icon {\n        font-size: 200px;\n    }\n\n    .upload_text {\n        font-size: 45px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabled_upload_container": "x94i8ma4bM0Cl5uewHe_",
	"ellipsis_icon": "ak5AppG7z2V3KA950D31",
	"upload_text": "Q1_MQOhhq0l_qgqcG4Mg"
};
export default ___CSS_LOADER_EXPORT___;
